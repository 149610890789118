.background {
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
}

.container {
  background: white;
  width: 100%;
  max-width: 600px;
  border-radius: 3px;
  padding: 20px;
}

.header {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close {
  font-size: 24px;
  opacity: 0.6;

  &:hover {
    cursor: pointer;
    opacity: 1;
  }
}

.description {
  margin-bottom: 20px;
}

.form {
  width: 100%;
  display: block;
  text-align: right;
}

.input {
  width: 100%;
  padding: 10px 5px;
  font-size: 18px;
  text-align: center;
  border: 2px solid #ddd;
  border-radius: 3px;
}

.submit {
  margin-top: 10px;
  padding: 5px 10px;
}
