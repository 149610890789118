.background {
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
}

.container {
  background: white;
  width: 100%;
  max-width: 600px;
  border-radius: 3px;
  padding: 30px 20px;
}

.header {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close {
  font-size: 24px;
  opacity: 0.6;

  &:hover {
    cursor: pointer;
    opacity: 1;
  }
}

.description {
  margin-bottom: 20px;
}

.content {
  margin-bottom: 20px;
}

.playerItem {
  width: 100%;
  padding: 8px;
  background: #efefef;
  border-radius: 3px;
  margin: 2px 0px;
  &:hover {
    cursor: pointer;
  }
}

.playerItemSelected {
  width: 100%;
  padding: 8px;
  background: red;
  color: white;
  border-radius: 3px;
  margin: 2px 0px;

  &:hover {
    cursor: pointer;
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.cancel {
  margin-right: 20px;
  &:hover {
    cursor: pointer;
  }
}

.resetGame {
  color: red;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}

.resetGameDisabled {
  color: gray;
  cursor: default;
}

.select {
  margin-left: 10px;
  margin-right: 10px;
  width: 80px;
  font-size: 14px;
  font-weight: 600;
}
