.container {
  width: 240px;
  height: 240px;
  border: 2px solid #333;
  border-radius: 3px;
  margin: 20px;
}

.playerCards {
  display: flex;
}

.card {
  width: 50%;
  height: 140px;
  background: #efefef;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid;
}

.card:nth-child(1) {
  border-right: 1px solid #333;
}

.card:nth-child(2) {
  border-left: 1px solid #333;
}

.details {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.name {
  margin-bottom: 5px;
}

.you {
  color: green;
}

.revealed {
  color: red;
}

.hidden {
  color: green;
}
