.container {
  position: fixed;
  width: 100%;
  left: 0px;
  bottom: 0px;
  height: 160px;
  border-top: 2px solid #333;
  padding: 10px;
  overflow-y: scroll;
  vertical-align: top;
  background: white;
}

.gameInfo {
  width: 30%;
  display: inline-block;
  vertical-align: top;
  padding: 20px;
}

.actions {
  width: 70%;
  display: inline-block;
  vertical-align: top;
}

.actions button {
  margin: 10px;
}
