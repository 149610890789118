.container {
  width: 100%;
  height: 40px;
  border-bottom: 2px solid #333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
}

.right {
  display: flex;
  flex-direction: row;
}

.action,
.nameAction {
  margin-right: 20px;
  color: black;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.nameAction {
  font-weight: 600;
}
