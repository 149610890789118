.container {
  max-width: 500px;
  margin: 0px auto;
  margin-top: 60px;
}

.back {
  color: black;
  text-decoration: none;
  margin-bottom: 30px;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.backIcon {
  margin-right: 10px;
}

.header {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 600;
}

.description {
  line-height: 1.5;
}

.settings {
  margin-top: 40px;
}

.setting {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}
.action {
  font-style: italic;
}

.button {
  padding: 5px 10px;
}
