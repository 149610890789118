.wrapper {
  width: 100%;
  text-align: center;
  margin-top: 10%;
}

.container {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.buttonContainer {
  width: 100%;
  max-width: 300px;
}

.createButton {
  width: 100%;
  margin-bottom: 20px;
  height: 40px;
}

.joinInput {
  border-width: 0px;
  border: 1px solid lightgray;
  display: inline-block;
  padding: 10px 15px;
  border-radius: 3px;
}

.joinButton {
  border: 1px solid #aaa;
  border-radius: 3px;
  display: inline-block;
  padding: 10px 15px;
}
