.container {
  margin: auto;
  width: 600px;
  margin-top: 8%;
}

.header {
  font-size: 3em;
  font-weight: 600;
  margin-bottom: 40px;
}

.description {
  font-size: 18px;
  margin-bottom: 20px;
}

.link {
  font-size: 18px;
}
